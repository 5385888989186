import * as PIXI from "pixi.js";
const PAD = 0.02;
const HEIGHT = 0.3;
const FONT_SIZE = 0.05;
export class Dialog extends PIXI.Container {
    constructor(app, title, content, items = []) {
        super();
        this.app = app;
        this.title = title;
        this.content = content;
        this.items = items;
        this.bg = createBg();
        this.addChild(this.bg);
        this.titleText = new PIXI.Text(this.title);
        this.titleText.anchor.set(0, 1);
        this.addChild(this.titleText);
        this.contentText = new PIXI.Text(this.content);
        this.addChild(this.contentText);
    }
    show(container) {
        return new Promise((resolve) => {
            container.addChild(this);
            const onResize = () => {
                this.update(this.getWidth(), this.getHeight());
            };
            window.addEventListener("resize", onResize);
            const exit = (index) => {
                window.removeEventListener("resize", onResize);
                container.removeChild(this);
                resolve(index);
            };
            if (this.items.length > 0) {
                this.itemMenu = new ItemMenu(this.items, (index) => {
                    exit(index);
                });
                this.addChild(this.itemMenu);
            }
            this.update(this.getWidth(), this.getHeight());
            this.eventMode = 'static';
            this.on("pointertap", (_) => {
                exit(-1);
            });
        });
    }
    update(screenWidth, screenHeight) {
        this.hitArea = new PIXI.Rectangle(0, 0, screenWidth, screenHeight);
        const y = screenHeight * (1 - HEIGHT);
        const pad = screenHeight * PAD;
        const textStyle = new PIXI.TextStyle({
            fill: "#fff",
            fontSize: Math.max(screenHeight * FONT_SIZE, 14),
            breakWords: true,
            wordWrap: true,
            wordWrapWidth: screenWidth - pad * 2,
        });
        this.bg.width = screenWidth;
        this.bg.height = screenHeight * HEIGHT;
        this.bg.y = y;
        this.titleText.style = new PIXI.TextStyle(textStyle);
        this.titleText.x = pad;
        this.titleText.y = y - pad;
        this.contentText.style = new PIXI.TextStyle(textStyle);
        this.contentText.x = pad;
        this.contentText.y = y + pad;
        this.itemMenu?.update(screenWidth, screenHeight);
    }
    getWidth() {
        return this.app.renderer.width / this.app.stage.scale.x;
    }
    getHeight() {
        return this.app.renderer.height / this.app.stage.scale.y;
    }
}
class ItemMenu extends PIXI.Container {
    constructor(items = [], onItemTap) {
        super();
        this.itemTexts = [];
        this.bg = createBg();
        this.addChild(this.bg);
        for (let i = 0; i < items.length; i++) {
            const text = new PIXI.Text(items[i]);
            text.anchor.set(0.5, 0);
            // Interaction
            text.eventMode = 'static';
            text.on("pointertap", () => {
                onItemTap(i);
            });
            this.itemTexts.push(text);
            this.addChild(text);
        }
    }
    update(screenWidth, screenHeight) {
        const pad = screenHeight * PAD;
        const width = Math.max(screenWidth * 0.3, 120);
        const textStyle = new PIXI.TextStyle({
            fill: "#fff",
            align: "center",
            fontSize: Math.max(screenHeight * FONT_SIZE, 14),
            breakWords: true,
            wordWrap: true,
            wordWrapWidth: width - pad * 2,
        });
        let height = pad;
        let textMetrics;
        for (const text of this.itemTexts) {
            text.style = textStyle;
            text.x = width / 2;
            text.y = height;
            textMetrics = PIXI.TextMetrics.measureText(text.text, textStyle);
            height += textMetrics.height + pad;
            text.hitArea = new PIXI.Rectangle(-width / 2, -pad, width, screenHeight);
        }
        this.bg.width = width;
        this.bg.height = height;
        this.pivot.x = width / 2;
        this.pivot.y = height / 2;
        this.x = screenWidth / 2;
        this.y = (screenHeight * (1 - HEIGHT)) / 2;
    }
}
function createBg() {
    const bg = new PIXI.Graphics();
    bg.beginFill(0x000000);
    bg.drawRect(0, 0, 1, 1);
    bg.endFill();
    bg.alpha = 0.5;
    return bg;
}
