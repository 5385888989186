import * as PIXI from "pixi.js";
import path from "path-browserify";
import { ImageLayer } from "./ImageLayer";
import { TileLayer } from "./TileLayer";
import { TileSet } from "./TileSet";
export class TiledMap extends PIXI.Container {
    constructor(data, route) {
        super();
        this.data = data;
        this.route = route;
        this.tileSets = [];
        this.layers = {};
        this.width = this.data.tilewidth * this.data.width;
        this.height = this.data.tileheight * this.data.height;
        this.create();
    }
    create() {
        const bgColor = this.data?.backgroundcolor;
        if (bgColor) {
            // Draw background color
            const background = new PIXI.Graphics();
            background.beginFill(new PIXI.Color(this.data?.backgroundcolor ?? "#0").toHex());
            background.drawRect(0, 0, this.width, this.height);
            background.endFill();
            this.addChild(background);
        }
        // Parse tilesets
        this.data.tilesets.forEach((tileSet) => {
            this.tileSets.push(new TileSet(tileSet, this.route));
        });
        // Parse layers
        this.data.layers.forEach((layerData) => {
            switch (layerData.type) {
                case "tilelayer": {
                    if (layerData.name === "Collisions") {
                        // Treat collision layer specially
                        this.collisionLayer = new TileLayer(layerData, this.data, this.tileSets, true);
                    }
                    else {
                        const tileLayer = new TileLayer(layerData, this.data, this.tileSets);
                        this.layers[layerData.name] = tileLayer;
                        this.addChild(tileLayer);
                    }
                    break;
                }
                case "imagelayer": {
                    const imageLayer = new ImageLayer(layerData, this.route);
                    this.layers[layerData.name] = imageLayer;
                    this.addChild(imageLayer);
                    break;
                }
            }
        });
    }
}
TiledMap.loaderParser = {
    extension: {
        name: "Tiled Map Loader Parser",
        priority: PIXI.LoaderParserPriority.Normal,
        type: PIXI.ExtensionType.LoadParser,
    },
    test(url) {
        return url.endsWith(".tiled.json");
    },
    async load(url) {
        const response = await PIXI.settings.ADAPTER.fetch(url);
        const mapData = await response.json();
        const route = path.dirname(url);
        return new TiledMap(mapData, route);
    },
};
