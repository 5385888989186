import * as PIXI from "pixi.js";
export class ImageLayer extends PIXI.Container {
    constructor(data, route) {
        super();
        this.data = data;
        this.visible = data.visible;
        this.alpha = data.opacity;
        if (data.image) {
            const image = PIXI.Sprite.from(route + "/" + data.image, {
                scaleMode: PIXI.SCALE_MODES.NEAREST,
            });
            this.addChild(image);
        }
    }
}
